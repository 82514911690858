#titleAcronym {
  font-size: 1.8em;
  font-weight: 600;
}

.titleContainer {
  font-weight: 600;
  color: #ed1c24;
  font-size: 1.18em;
}

.negotiationContainer {
  text-align: center;
  padding: 2rem 1.8rem 1.8rem;
  margin-bottom: 1.7rem;
}

.renegotiateBtn {
  background-color: black;
}

.negotiationContainer h1 {
  /* font-size: .9375em; */
  font-weight: 600;
  margin-bottom: 1.56rem;
}

.negotiationContainer h2 {
  /* font-size: 2em; */
}

.negotiationContainer h3 {
  font-size: 0.9375em;
  font-weight: 600;
  margin-bottom: 1.56rem;
}

.line {
  width: 70%;
  height: 1px;
  margin: 2.3rem auto;
  background-color: #000000;
}

@media (max-width: 600px) {
  #titleAcronym {
    font-size: "1.18em";
    font-weight: 600;
  }
}

@media (min-width: 768px) {
  .negotiationContainer {
    text-align: center;
    padding: 2rem 1.8rem 1.8rem;
    margin-bottom: 1.7rem;
  }

  .titleContainer {
    margin-bottom: 2.875rem;
  }

  .negotiationContainer .button {
    margin: 0 auto;
    width: 174px;
  }

  .negotiationContainer h1 {
    font-size: 1.875em;
    font-weight: 600;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 1.56rem;
  }

  .negotiationContainer h2 {
    font-size: 1.6em;
  }

  .negotiationContainer h3 {
    font-size: 0.9375em;
    font-weight: 600;
    margin-bottom: 1.56rem;
  }

  .renegotiateContainer h3 {
    margin-bottom: 2.68rem;
  }

  .line {
    opacity: 0;
    margin: 1.75rem auto;
  }
}
