header {
  padding-top: 1rem;
  text-align: center;
}
.QuizContainer {
  flex-grow: 1;
}

header,
.QuizContainer,
footer {
  flex-shrink: 0;
}

header img {
  width: 108px;
  height: auto;
}

#um-studios-logo {
  width: 100%;
  height: auto;
  text-align: center;
}

#um-studios-logo > img {
  width: 171px;
}

#credits {
  background-image: url(/static/media/Credits.d60b7ca9.png);
  width: 89px;
  height: 43px;
  background-size: contain;
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  /* margin-bottom: 20px; */
}

#credits .credits_text,
#contact .contact_text {
  visibility: hidden;
  width: 150px;
  background-color: white;
  color: #636466;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 35%;
  margin-left: -60px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
  line-height: 1.5;
}

#credits .credits_text::after,
#contact .contact_text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

#contact:hover .contact_text,
#contact:active .contact_text,
#contact:focus .contact_text {
  visibility: visible;
}

#contact {
  width: 89px;
  height: 32px;
  background-size: contain;
  position: relative;
  cursor: pointer;
  top: 9px;

  /* float: right; */
  /* vertical-align: middle; */
  /* right: 80px; */
  /* border-right: 1px solid red; */
  padding-top: 5px;
  color: red;
  font-size: 18px;
  font-weight: 600;
}

#contact .contact_text {
  width: 200px;
  left: -8%;
  font-size: 12px;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  footer {
    padding-bottom: 15%;
  }
  #credits {
    margin-bottom: 20px;
  }

  #contact {
    margin: 0 auto;
  }

  #credits:active .credits_text,
  #credits:hover .credits_text,
  #credits:focus .credits_text,
  #contact:hover .contact_text,
  #contact:active .contact_text,
  #contact:focus .contact_text {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  #credits {
    right: 60px;
    float: right;
  }

  #contact {
    float: right;
    vertical-align: middle;
    right: 80px;
    border-right: 1px solid red;
  }

  #credits:hover .credits_text {
    visibility: visible;
  }
  header {
    padding: 2.5rem 0 0 2.5rem;
    text-align: left;
  }

  header img {
    width: 135px;
  }

  footer {
    padding: 0 0 1rem 2.5rem;
  }

  #um-studios-logo {
    text-align: left;
    display: inline;
  }

  #um-studios-logo > img {
    width: 193px;
    vertical-align: middle;
  }
}
