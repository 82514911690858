@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800;900&display=swap);
/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

body {
  margin: 0;
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  background: url(/static/media/bg.ee20439a.png) no-repeat center center fixed;
  background-size: cover;
  box-sizing: border-box;
}

html, body, #root, .App {
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* Layout */
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem;
  padding-bottom: 2.5rem;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  flex: 1 1;
  max-height:  100%;
  max-width: 100%;
}

/* .column:nth-of-type(1) {
  flex-grow: 0;
} */
.column:nth-of-type(2) {
  flex-grow: 2;
  justify-content: space-around;
}

.column.flex-start {
  justify-content: flex-start;
}

/* Buttons */
.button {
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-radius: 5px;
  background-color: #ed1c24;
  padding: 10px 30px;
  color: white;
  text-decoration: none;
}


/* Responsive Images */
.imgContainer {
  margin: 0 auto;
}

.imgContainer > img {
  display: block;
  max-width: 100%;
}
main {
  text-align: center;
}

.desktop h1 {
  font-size: 2.18em;
  font-weight: bold;
  line-height: 1.15;
}

.desktop p {
  font-size: 0.92em;
  font-weight: 600;
  line-height: 1.8;
  color: #7c7c7c;
}

.mobile {
  margin-bottom: 1.9rem;
}

.mobile h1 {
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.15;
}

.mobile p {
  font-size: 0.9em;
  font-weight: 600;
  line-height: 1.3;
  color: #7c7c7c;
}

.desktop {
  display: none;
}

.imgContainer {
  width: 275px;
}
.landing .column {
  justify-content: flex-start;
}
.landing .column:nth-of-type(1) {
  flex: 0 1 auto;
}
.landing .column:nth-of-type(2) {
  flex: 2 0 auto;
}

/* button */
.button {
  width: 165px;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: auto;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.button.quizLink {
  width: 277px;
}
.button span > span {
  position: absolute;
  transform: translateX(2px);
}

.pulse-button {
  -webkit-animation: pulse 1.5s infinite;
          animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 rgb(237, 28, 36, 0.5);
  transform-origin: center;
}
.pulse-button:hover {
  -webkit-animation: none;
          animation: none;
}
@media (min-width: 768px) {
  .column {
    align-items: flex-start;
  }

  .landing .column:nth-of-type(1) {
    justify-content: center;
    flex: 40% 1;
  }

  .landing .column:nth-of-type(2) {
    justify-content: center;
    flex: 50% 1;
    padding-left: 1.5rem;
  }
  .button {
    margin: 0;
    margin: initial;
    width: 277px;
  }

  .button:hover > span > span {
    -webkit-animation-name: moveRight;
            animation-name: moveRight;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: 3;
            animation-iteration-count: 3;
  }

  .imgContainer {
    width: 100%;
  }

  .landingBtn {
    flex: initial;
  }
  .pulse-button {
    transform-origin: center;
  }

  .container.landing {
    padding: 0 0.7rem;
  }
}

@-webkit-keyframes moveRight {
  0% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(2px);
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(2px);
  }
}

@media (max-width: 320px) {
  .imgContainer {
    width: 225px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  main {
    margin-top: 1rem;
  }

  .mobile {
    margin-bottom: 2.2rem;
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(0.98);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(28, 29, 31, 0);
  }
  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(28, 29, 31, 0);
  }
  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

header {
  padding-top: 1rem;
  text-align: center;
}
.QuizContainer {
  flex-grow: 1;
}

header,
.QuizContainer,
footer {
  flex-shrink: 0;
}

header img {
  width: 108px;
  height: auto;
}

#um-studios-logo {
  width: 100%;
  height: auto;
  text-align: center;
}

#um-studios-logo > img {
  width: 171px;
}

#credits {
  background-image: url(/static/media/Credits.d60b7ca9.png);
  width: 89px;
  height: 43px;
  background-size: contain;
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  /* margin-bottom: 20px; */
}

#credits .credits_text,
#contact .contact_text {
  visibility: hidden;
  width: 150px;
  background-color: white;
  color: #636466;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 35%;
  margin-left: -60px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
  line-height: 1.5;
}

#credits .credits_text::after,
#contact .contact_text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

#contact:hover .contact_text,
#contact:active .contact_text,
#contact:focus .contact_text {
  visibility: visible;
}

#contact {
  width: 89px;
  height: 32px;
  background-size: contain;
  position: relative;
  cursor: pointer;
  top: 9px;

  /* float: right; */
  /* vertical-align: middle; */
  /* right: 80px; */
  /* border-right: 1px solid red; */
  padding-top: 5px;
  color: red;
  font-size: 18px;
  font-weight: 600;
}

#contact .contact_text {
  width: 200px;
  left: -8%;
  font-size: 12px;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  footer {
    padding-bottom: 15%;
  }
  #credits {
    margin-bottom: 20px;
  }

  #contact {
    margin: 0 auto;
  }

  #credits:active .credits_text,
  #credits:hover .credits_text,
  #credits:focus .credits_text,
  #contact:hover .contact_text,
  #contact:active .contact_text,
  #contact:focus .contact_text {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  #credits {
    right: 60px;
    float: right;
  }

  #contact {
    float: right;
    vertical-align: middle;
    right: 80px;
    border-right: 1px solid red;
  }

  #credits:hover .credits_text {
    visibility: visible;
  }
  header {
    padding: 2.5rem 0 0 2.5rem;
    text-align: left;
  }

  header img {
    width: 135px;
  }

  footer {
    padding: 0 0 1rem 2.5rem;
  }

  #um-studios-logo {
    text-align: left;
    display: inline;
  }

  #um-studios-logo > img {
    width: 193px;
    vertical-align: middle;
  }
}

.questionOption {
  padding: .6rem .6rem .6rem 1.2rem;
  cursor: pointer;
  margin-bottom: 20px;
  border: solid 1.5px #e0e3ea;
  border-radius: 50px;
  font-weight: 600;
  background-color: #FFFFFF;
  width: 100%;
}

.questionOption:focus,
.questionOption:active {
  border-color: #ed1c24;
}

@media (min-width: 768px) {
  .questionOption {
    max-width: 75%;
  }
}
input {
  display: block;
}

.hobbyQuestion .button {
  border: none;
}

@media (min-width: 768px) {

  .hobbyQuestion input[type=text] {
    width: 100%;
  }

  .hobbyQuestion .button {
    margin: inherit;
    /* width: 120px; */
    text-transform: uppercase;
  }
}

#backBtn {
  cursor: pointer;
  background-color: grey;
  width: auto;
  position: relative;
  top: -2rem;
}

#imgColumn {
  justify-content: end;
  top: -100px;
  position: relative;
}

.quiz > .column:nth-of-type(2) {
  display: none;
}

.container.quiz {
  width: 75%;
}

.question {
  width: 100%;
  margin-bottom: 2.4rem;
}

.questionHeader {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  padding: 0.9rem;
}

.QuizContainer {
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.quiz .imgContainer {
  display: none;
  height: auto;
  max-height: 100%;
}

.questionImage {
  display: none;
  max-width: 100%;
  max-height: 700px;
  margin: 0 auto;
}

/*  Media Query for Questions */

@media (min-width: 1050px) {
  .quiz > .column:nth-of-type(2) {
    align-items: center;
    display: flex;
    /* max-height: 50vh;
    min-height: 200px; */
  }
  .quiz .imgContainer {
    display: block;
    max-height: 700px;
  }
  .questionHeader {
    text-align: left;
    padding: 0.9rem 0.9rem 0.9rem 0;
    font-size: 25px;
  }

  .QuizContainer {
    display: block;
    margin: 1rem 2.7rem;
  }
  .container.quiz {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1049px) {
  .quiz > .column:nth-of-type(2) {
    align-items: center;
    display: flex;
    /* max-height: 50vh;
    min-height: 200px; */
  }
  .quiz .imgContainer {
    display: block;
    max-height: 700px;
  }
  .questionHeader {
    text-align: left;
    padding: 0.9rem 0.9rem 0.9rem 0;
    font-size: 25px;
  }

  .QuizContainer {
    display: block;
    margin: 1rem 2.7rem;
    margin-bottom: 2.7rem;
  }
  .container.quiz {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-height: 786px) {
  .quiz .imgContainer {
    max-height: 400px;
    height: 100%;
  }

  .questionImage {
    max-height: 600px;
  }
}

@media (min-width: 768px) and (max-height: 960px) and (min-height: 787px) {
  .container.quiz {
    width: 75%;
  }
}

/* .oldtitle {
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.acceptOldTitleBtn {
  background-color: grey;
}

.oldTitlesContainer {
  margin-top: 1.56rem;
}

.acceptedTitleContainer {
  text-align: center;
  padding: 2rem 1.8rem 1.8rem;
  margin-bottom: 1.7rem;
}

.acceptedTitleContainer h1 {
  font-size: 0.9375em;
  font-weight: 600;
  margin-bottom: 1.56rem;
}

.acceptedTitleContainer .titleContainer {
  font-size: 1.18em;
}

.acceptedTitleContainer h3 {
  font-size: 0.9375em;
  font-weight: 600;
  margin-bottom: 1.56rem;
}

.hidden {
  display: none;
}

#canvas2,
#canvas {
  display: none;
}

#canvas2 {
  margin-bottom: 0.7rem;
}

#downloadLink {
  display: none;
}

#fontloader {
  opacity: 0;
  /* display: none; */
  /* font-family: "GothamBold"; */
}

.custom-image-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-image-input {
  margin: auto;
  padding-left: 60px;
}

.custom-image-input::before {
  content: "UPLOAD PHOTO";
  display: inline-block;
  background: grey;
  border: 1px solid #999;
  border-radius: 5px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  color: white;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 700;
  font-size: 10pt;
  padding: 10px 30px;
}

.custom-image-input:hover::before {
  border-color: black;
}

canvas.headshot {
  width: 100%;
  height: auto;
}
.headshotUpdate {
  margin-left: 13%;
}

.headshotUpdate::-webkit-file-upload-button {
  visibility: hidden;
}

.headshotUpdate::before {
  content: "CHANGE PHOTO";
  display: inline-block;
  background: grey;
  border: 1px solid #999;
  border-radius: 5px;
  padding: 10px 58px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 10pt;
}

.headshotUpdate:hover::before {
  border-color: black;
}

#downloadLink {
  text-decoration: none;
}

#generateBtn,
#nameInput {
  z-index: 1000;
}

.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.file input {
  /* min-width: 14rem; */
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}

.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-radius: 5px;
  background-color: #c4c4c4;
  padding: 10px 30px;
  text-decoration: none;
}

.file-custom:after {
  content: "UPLOAD PHOTO";
}

#file2 {
  display: none;
  width: 174px;
  margin: 0 auto;
  margin-bottom: 1.7rem;
}

#input2,
#file-custom2 {
  width: 174px;
  height: 38px;
}

#file-custom2:after {
  content: "CHANGE PHOTO";
}

#headshotBtnContainer #downloadLink {
  width: 174px;
  margin: 0 auto;
}
#headshotBtnContainer #downloadLink .button {
  width: 174px;
}

#nameInput {
  padding: 0.6rem 0.6rem 0.6rem 1.2rem;
  cursor: pointer;
  margin: 1.7rem auto;
  width: 75%;
  border: solid 1.5px #e0e3ea;
  border-radius: 50px;
  font-weight: 600;
  background-color: #ffffff;
}

@media (min-width: 768px) {
  .acceptedTitleContainer {
    max-width: 32rem;
    text-align: center;
    padding: 0;
    padding-bottom: 1.8rem;
    margin: 0 auto;
    flex: 1 1;
  }
  .acceptedTitleContainer h1 {
    font-size: 1.875em;
    font-weight: 600;
    margin-bottom: 1.6rem;
  }

  .acceptedTitleContainer h3 {
    font-size: 1.3875em;
    margin-bottom: 1.56rem;
  }

  .acceptedTitleContainer .titleContainer {
    margin-bottom: 2.375rem;
    font-size: 2.1875em;
  }

  #nameInput input[type="text"] {
    width: 100%;
  }

  #headshotBtnContainer {
    display: flex;
    flex: 1 1;
    justify-content: space-evenly;
  }
  .headshotUpdate {
    margin-left: 0;
  }

  canvas.headshot {
    max-width: 355px;
    height: auto;
    margin: 0 auto;
  }
  #canvas2 {
    margin-bottom: 2.7rem;
  }

  #file1 {
    width: 277px;
  }

  #file2 {
    margin: 0;
  }

  #headshotBtnContainer #downloadLink {
    margin: 0;
    height: 100%;
  }
  #generateBtn {
    margin: 0 auto;
  }
}

#titleAcronym {
  font-size: 1.8em;
  font-weight: 600;
}

.titleContainer {
  font-weight: 600;
  color: #ed1c24;
  font-size: 1.18em;
}

.negotiationContainer {
  text-align: center;
  padding: 2rem 1.8rem 1.8rem;
  margin-bottom: 1.7rem;
}

.renegotiateBtn {
  background-color: black;
}

.negotiationContainer h1 {
  /* font-size: .9375em; */
  font-weight: 600;
  margin-bottom: 1.56rem;
}

.negotiationContainer h2 {
  /* font-size: 2em; */
}

.negotiationContainer h3 {
  font-size: 0.9375em;
  font-weight: 600;
  margin-bottom: 1.56rem;
}

.line {
  width: 70%;
  height: 1px;
  margin: 2.3rem auto;
  background-color: #000000;
}

@media (max-width: 600px) {
  #titleAcronym {
    font-size: "1.18em";
    font-weight: 600;
  }
}

@media (min-width: 768px) {
  .negotiationContainer {
    text-align: center;
    padding: 2rem 1.8rem 1.8rem;
    margin-bottom: 1.7rem;
  }

  .titleContainer {
    margin-bottom: 2.875rem;
  }

  .negotiationContainer .button {
    margin: 0 auto;
    width: 174px;
  }

  .negotiationContainer h1 {
    font-size: 1.875em;
    font-weight: 600;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 1.56rem;
  }

  .negotiationContainer h2 {
    font-size: 1.6em;
  }

  .negotiationContainer h3 {
    font-size: 0.9375em;
    font-weight: 600;
    margin-bottom: 1.56rem;
  }

  .renegotiateContainer h3 {
    margin-bottom: 2.68rem;
  }

  .line {
    opacity: 0;
    margin: 1.75rem auto;
  }
}

@media (min-width: 768px) {
  .App {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .container {
    flex-direction: row;
    height: 100%;
    flex: 1 1;
    padding: 0;
  }

  .column.flex-start {
    justify-content: center;
  }

  .column:nth-of-type(1) {
    flex-grow: 1;
  }
  .column:nth-of-type(2) {
    align-items: flex-start;
    justify-content: space-evenly;
  }
  main {
    display: block;
    text-align: left;
    margin-bottom: 40px;
  }

  .desktop {
    display: inline;
  }

  .mobile {
    display: none;
  }

  .line {
    opacity: 0;
  }

}

@media (min-width: 768px) and (max-width: 1500px) {
  .column:nth-of-type(2) {
    flex-grow: 1;
  }
}
