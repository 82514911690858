.questionOption {
  padding: .6rem .6rem .6rem 1.2rem;
  cursor: pointer;
  margin-bottom: 20px;
  border: solid 1.5px #e0e3ea;
  border-radius: 50px;
  font-weight: 600;
  background-color: #FFFFFF;
  width: 100%;
}

.questionOption:focus,
.questionOption:active {
  border-color: #ed1c24;
}

@media (min-width: 768px) {
  .questionOption {
    max-width: 75%;
  }
}