html {
  background: url('./assets/bg.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-sizing: border-box;
}

html, body, #root, .App {
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* Layout */
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem;
  padding-bottom: 2.5rem;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  flex: 1;
  max-height:  100%;
  max-width: 100%;
}

/* .column:nth-of-type(1) {
  flex-grow: 0;
} */
.column:nth-of-type(2) {
  flex-grow: 2;
  justify-content: space-around;
}

.column.flex-start {
  justify-content: flex-start;
}

/* Buttons */
.button {
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-radius: 5px;
  background-color: #ed1c24;
  padding: 10px 30px;
  color: white;
  text-decoration: none;
}


/* Responsive Images */
.imgContainer {
  margin: 0 auto;
}

.imgContainer > img {
  display: block;
  max-width: 100%;
}