@media (min-width: 768px) {
  .App {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .container {
    flex-direction: row;
    height: 100%;
    flex: 1;
    padding: 0;
  }

  .column.flex-start {
    justify-content: center;
  }

  .column:nth-of-type(1) {
    flex-grow: 1;
  }
  .column:nth-of-type(2) {
    align-items: flex-start;
    justify-content: space-evenly;
  }
  main {
    display: block;
    text-align: left;
    margin-bottom: 40px;
  }

  .desktop {
    display: inline;
  }

  .mobile {
    display: none;
  }

  .line {
    opacity: 0;
  }

}

@media (min-width: 768px) and (max-width: 1500px) {
  .column:nth-of-type(2) {
    flex-grow: 1;
  }
}