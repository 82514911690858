input {
  display: block;
}

.hobbyQuestion .button {
  border: none;
}

@media (min-width: 768px) {

  .hobbyQuestion input[type=text] {
    width: 100%;
  }

  .hobbyQuestion .button {
    margin: inherit;
    /* width: 120px; */
    text-transform: uppercase;
  }
}
