@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800;900&display=swap);

.acceptedTitleContainer {
  text-align: center;
  padding: 2rem 1.8rem 1.8rem;
  margin-bottom: 1.7rem;
}

.acceptedTitleContainer h1 {
  font-size: 0.9375em;
  font-weight: 600;
  margin-bottom: 1.56rem;
}

.acceptedTitleContainer .titleContainer {
  font-size: 1.18em;
}

.acceptedTitleContainer h3 {
  font-size: 0.9375em;
  font-weight: 600;
  margin-bottom: 1.56rem;
}

.hidden {
  display: none;
}

#canvas2,
#canvas {
  display: none;
}

#canvas2 {
  margin-bottom: 0.7rem;
}

#downloadLink {
  display: none;
}

#fontloader {
  opacity: 0;
  /* display: none; */
  /* font-family: "GothamBold"; */
}

.custom-image-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-image-input {
  margin: auto;
  padding-left: 60px;
}

.custom-image-input::before {
  content: "UPLOAD PHOTO";
  display: inline-block;
  background: grey;
  border: 1px solid #999;
  border-radius: 5px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  color: white;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 700;
  font-size: 10pt;
  padding: 10px 30px;
}

.custom-image-input:hover::before {
  border-color: black;
}

canvas.headshot {
  width: 100%;
  height: auto;
}
.headshotUpdate {
  margin-left: 13%;
}

.headshotUpdate::-webkit-file-upload-button {
  visibility: hidden;
}

.headshotUpdate::before {
  content: "CHANGE PHOTO";
  display: inline-block;
  background: grey;
  border: 1px solid #999;
  border-radius: 5px;
  padding: 10px 58px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 10pt;
}

.headshotUpdate:hover::before {
  border-color: black;
}

#downloadLink {
  text-decoration: none;
}

#generateBtn,
#nameInput {
  z-index: 1000;
}

.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.file input {
  /* min-width: 14rem; */
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}

.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border-radius: 5px;
  background-color: #c4c4c4;
  padding: 10px 30px;
  text-decoration: none;
}

.file-custom:after {
  content: "UPLOAD PHOTO";
}

#file2 {
  display: none;
  width: 174px;
  margin: 0 auto;
  margin-bottom: 1.7rem;
}

#input2,
#file-custom2 {
  width: 174px;
  height: 38px;
}

#file-custom2:after {
  content: "CHANGE PHOTO";
}

#headshotBtnContainer #downloadLink {
  width: 174px;
  margin: 0 auto;
}
#headshotBtnContainer #downloadLink .button {
  width: 174px;
}

#nameInput {
  padding: 0.6rem 0.6rem 0.6rem 1.2rem;
  cursor: pointer;
  margin: 1.7rem auto;
  width: 75%;
  border: solid 1.5px #e0e3ea;
  border-radius: 50px;
  font-weight: 600;
  background-color: #ffffff;
}

@media (min-width: 768px) {
  .acceptedTitleContainer {
    max-width: 32rem;
    text-align: center;
    padding: 0;
    padding-bottom: 1.8rem;
    margin: 0 auto;
    flex: 1;
  }
  .acceptedTitleContainer h1 {
    font-size: 1.875em;
    font-weight: 600;
    margin-bottom: 1.6rem;
  }

  .acceptedTitleContainer h3 {
    font-size: 1.3875em;
    margin-bottom: 1.56rem;
  }

  .acceptedTitleContainer .titleContainer {
    margin-bottom: 2.375rem;
    font-size: 2.1875em;
  }

  #nameInput input[type="text"] {
    width: 100%;
  }

  #headshotBtnContainer {
    display: flex;
    flex: 1;
    justify-content: space-evenly;
  }
  .headshotUpdate {
    margin-left: 0;
  }

  canvas.headshot {
    max-width: 355px;
    height: auto;
    margin: 0 auto;
  }
  #canvas2 {
    margin-bottom: 2.7rem;
  }

  #file1 {
    width: 277px;
  }

  #file2 {
    margin: 0;
  }

  #headshotBtnContainer #downloadLink {
    margin: 0;
    height: 100%;
  }
  #generateBtn {
    margin: 0 auto;
  }
}
