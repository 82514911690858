/* .oldtitle {
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.acceptOldTitleBtn {
  background-color: grey;
}

.oldTitlesContainer {
  margin-top: 1.56rem;
}
