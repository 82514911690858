#backBtn {
  cursor: pointer;
  background-color: grey;
  width: auto;
  position: relative;
  top: -2rem;
}

#imgColumn {
  justify-content: end;
  top: -100px;
  position: relative;
}

.quiz > .column:nth-of-type(2) {
  display: none;
}

.container.quiz {
  width: 75%;
}

.question {
  width: 100%;
  margin-bottom: 2.4rem;
}

.questionHeader {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  padding: 0.9rem;
}

.QuizContainer {
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.quiz .imgContainer {
  display: none;
  height: auto;
  max-height: 100%;
}

.questionImage {
  display: none;
  max-width: 100%;
  max-height: 700px;
  margin: 0 auto;
}

/*  Media Query for Questions */

@media (min-width: 1050px) {
  .quiz > .column:nth-of-type(2) {
    align-items: center;
    display: flex;
    /* max-height: 50vh;
    min-height: 200px; */
  }
  .quiz .imgContainer {
    display: block;
    max-height: 700px;
  }
  .questionHeader {
    text-align: left;
    padding: 0.9rem 0.9rem 0.9rem 0;
    font-size: 25px;
  }

  .QuizContainer {
    display: block;
    margin: 1rem 2.7rem;
  }
  .container.quiz {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1049px) {
  .quiz > .column:nth-of-type(2) {
    align-items: center;
    display: flex;
    /* max-height: 50vh;
    min-height: 200px; */
  }
  .quiz .imgContainer {
    display: block;
    max-height: 700px;
  }
  .questionHeader {
    text-align: left;
    padding: 0.9rem 0.9rem 0.9rem 0;
    font-size: 25px;
  }

  .QuizContainer {
    display: block;
    margin: 1rem 2.7rem;
    margin-bottom: 2.7rem;
  }
  .container.quiz {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-height: 786px) {
  .quiz .imgContainer {
    max-height: 400px;
    height: 100%;
  }

  .questionImage {
    max-height: 600px;
  }
}

@media (min-width: 768px) and (max-height: 960px) and (min-height: 787px) {
  .container.quiz {
    width: 75%;
  }
}
