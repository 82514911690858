main {
  text-align: center;
}

.desktop h1 {
  font-size: 2.18em;
  font-weight: bold;
  line-height: 1.15;
}

.desktop p {
  font-size: 0.92em;
  font-weight: 600;
  line-height: 1.8;
  color: #7c7c7c;
}

.mobile {
  margin-bottom: 1.9rem;
}

.mobile h1 {
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.15;
}

.mobile p {
  font-size: 0.9em;
  font-weight: 600;
  line-height: 1.3;
  color: #7c7c7c;
}

.desktop {
  display: none;
}

.imgContainer {
  width: 275px;
}
.landing .column {
  justify-content: flex-start;
}
.landing .column:nth-of-type(1) {
  flex: 0 1 auto;
}
.landing .column:nth-of-type(2) {
  flex: 2 0 auto;
}

/* button */
.button {
  width: 165px;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: auto;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.button.quizLink {
  width: 277px;
}
.button span > span {
  position: absolute;
  transform: translateX(2px);
}

.pulse-button {
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 rgb(237, 28, 36, 0.5);
  transform-origin: center;
}
.pulse-button:hover {
  animation: none;
}
@media (min-width: 768px) {
  .column {
    align-items: flex-start;
  }

  .landing .column:nth-of-type(1) {
    justify-content: center;
    flex: 40%;
  }

  .landing .column:nth-of-type(2) {
    justify-content: center;
    flex: 50%;
    padding-left: 1.5rem;
  }
  .button {
    margin: initial;
    width: 277px;
  }

  .button:hover > span > span {
    animation-name: moveRight;
    animation-duration: 1s;
    animation-iteration-count: 3;
  }

  .imgContainer {
    width: 100%;
  }

  .landingBtn {
    flex: initial;
  }
  .pulse-button {
    transform-origin: center;
  }

  .container.landing {
    padding: 0 0.7rem;
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(2px);
  }
}

@media (max-width: 320px) {
  .imgContainer {
    width: 225px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  main {
    margin-top: 1rem;
  }

  .mobile {
    margin-bottom: 2.2rem;
  }
}

@keyframes pulse {
  0% {
    -moz-transform: scale(0.98);
    -ms-transform: scale(0.98);
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(28, 29, 31, 0);
  }
  100% {
    -moz-transform: scale(0.98);
    -ms-transform: scale(0.98);
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
